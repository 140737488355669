.divTextToHome {
    display: flex;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    margin-top: 30vh;
}

.iconBack{
    margin-right: 0px;
    font-size: 30px;
    margin-bottom: 6px;
}
