.content {
  height: 100vh;
  width: 100%;

  .divLayout {
    background-color: transparent;
    min-height: 86vh;
    // text-align: center;
    .header {
      position: "fixed" !important;
      z-index: 1 !important;
      width: "100%" !important;
      background-color: "white";
      padding: 0px 23px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);
      .headerProfile {
        display: flex;
        .subProfile {
          display: flex;
          min-width: 145px;
          cursor: pointer;
          .roleAndSchool {
            position: relative;
            right: 13px;
            .role {
              position: absolute;
              bottom: 7px;
              right: 0px;
            }
            .school {
              width: 150px;
              position: absolute;
              top: 12px;
              right: 0px;
              height: 35px;
              .fontStyle {
                font-size: 12px;
                color: #737373;
              }
            }
          }
          .tagStyle {
            padding: 0 9px 0 16px;
            cursor: pointer;
          }
        }
      }
    }
    .sideerMenu {
      overflow: hidden;
      height: 100vh;
      position: fixed;
      left: 0;
      padding-top: 92px;
      background-color: transparent;
    }
    .divChildren {
      padding: 24px;
      background-color: transparent;
      width: 100%;
      max-width: 1200px;
      overflow: hidden;
    }
  }

  .Rectangle {
    width: 62px;
    height: 18px;
    opacity: 0;
    border-radius: 15px;
    background-color: #eaeaea;
  }
  .inputSearch {
    width: 350px;
    height: 36px;
    border-radius: 20px;
    background-color: transparent;
    margin-left: 63px;
    margin-top: 0.65em;
    background-color: #f8f7f7;
    input {
      width: 90%;
      border-radius: unset !important;
      box-shadow: unset !important;
      background-color: #f8f7f7;
    }
  }
  .divMenu {
    width: 210px;
    padding-left: 30px;
    @media only screen and (max-width: 800px) {
      display: fixed;
      height: 200px;
      width: 100px;
      padding-left: 6px;
    }
    .divMenuItem {
      margin-bottom: 12px;
      span {
        @media only screen and (max-width: 800px) {
          display: none;
        }
      }
      .btnMenu {
        width: 160px;
        height: 48px !important;
        font-size: 16px;
        font-weight: 550;
        color: var(--black);
        padding: 0px 0px !important;
        border: none;
        font-weight: 600 !important;
        font-stretch: normal;
        color: var(--brown-grey) !important;
        text-align: left;
        padding-left: 24px !important;
        @media only screen and (max-width: 800px) {
          width: 50px;
          padding-left: 14px !important;
        }
      }
      .btnMenu:hover {
        // color: var(--shamrock) !important;
        color: none !important;
      }
      .btnMenuSelected {
        width: 160px;
        height: 48px !important;
        font-size: 16px;
        font-weight: 550;
        color: var(--black);
        padding: 0px 0px !important;
        border: none;
        font-weight: 600 !important;
        font-stretch: normal;
        color: #ee5294 !important;
        background-color: #ffeef5 !important;
        text-align: left;
        padding-left: 24px !important;
        @media only screen and (max-width: 800px) {
          width: 50px;
          padding-left: 14px !important;
        }
      }
    }
  }
  .navbarPDPA {
    position: fixed;
    display: flex;
    z-index: 100;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    height: 64px;
    bottom: 0px;
    align-items: center;
    padding: 8px 128px 8px 128px;
    justify-content: space-between;
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: var(--white);
      z-index: 90;
    }
    .linkAccept {
      color: #ffff;
      font-weight: 800;
      text-decoration: underline;
    }
  }
}

.siteLayout {
  background-color: transparent;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  @media only screen and (max-width: 800px) {
    margin-left: 0;
    background-color: transparent;
  }
}

.listIcon {
  background-color: #737373;
}
