.btnPrimary {
  width: 300px;
  // height: 48px;
  // border-radius: 30px;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  // margin: 0px 6px;

  @media (max-width: 1200px) {
    width: 200px;
  }

  @media (max-width: 600px) {
    width: 100px;
  }
}
