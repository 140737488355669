.card {
  // max-width: 291px;
  height: auto;
  border-radius: 12px;
  padding-bottom: 3em;

  .fix {
    :global .ant-card-body {
      padding: 16px;
    }

    :global .ant-card-meta-title {
      margin-bottom: 0px !important;
    }

    img.banner {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      object-fit: contain;
    }

    .meta {
      // padding-top: 13px;

      .host {
        // padding-top: 13px;
        font-size: 14px;
        color: #999899;
      }
    }

    .hostLogo {
      // width: 2.5vw;
      // height: auto;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: var(--white);
      border-radius: 50%;
      position: relative;
      top: -6vh;
      left: -0.5vw;
      padding: 4px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
    }

    .info {
      margin-top: -3em;

      .startDate {
        margin-top: 5px;
        color: red;
      }

      .wrapActionBtn {
        text-align: right;
        right: 10px;
        bottom: 16px;
        position: absolute;
      }

      .wrapActionBtnUp {
        text-align: right;
      }
      .btnCard {
        position: absolute;
        bottom: 1em;
        right: 1em;
        height: 2.5em;
        width: 8em;
        padding: 6px 16px;
        border-radius: 36px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .card {
    height: 50vh;
    width: 60vw;
    margin-bottom: 1.5em;

    .hostLogo {
      width: 20vw;
      height: auto;
    }
  }
}
