:root {
  --font-color: white;
  --copyrigth-bg-color: #ff3d82;
  --background-color: #0a0d14;
}

.divRoot {
  position: relative;

  .divImg {
    height: 128px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .divPadding {
    position: absolute;
    top: 127px;
    width: 100%;

    .divSiteMap {
      width: 100%;
      background-color: var(--background-color);
      padding: 40px 64px;
      text-align: left;

      @media only screen and (max-width: 800px) {
        padding: 24px 24px 16px 24px;
      }

      .logo {
        display: flex;
        margin-bottom: 40px;

        @media only screen and (max-width: 800px) {
          display: block;
        }
      }

      .wrapTitle {
        display: flex;

        @media only screen and (max-width: 800px) {
          display: block;
        }
      }

      span {
        display: block;
      }

      .columnTitle {
        // min-width: 450px;
        width: 100%;
      }

      .columnSocailTitle {
      }

      .title {
        font-family: Kanit;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.28px;
        text-align: left;
        color: var(--font-color);
        margin-bottom: 4px;
      }

      .list {
        cursor: pointer;
        font-family: Kanit;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.28px;
        text-align: left;
        max-width: 70%;
        color: var(--font-color);
        margin-bottom: 4px;

        @media only screen and (max-width: 800px) {
          max-width: 40%;
        }
      }

      .list_no_route {
        // cursor: pointer;
        font-family: Kanit;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.28px;
        text-align: left;
        max-width: 70%;
        color: var(--font-color);
        margin-bottom: 4px;

        @media only screen and (max-width: 800px) {
          max-width: 40%;
        }
      }

      .listSocial {
        font-family: Kanit;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--font-color);
        margin-bottom: 8px;
        display: flex;

        // opacity: 0.75;
        .Oval {
          cursor: pointer;
          width: 36px;
          height: 36px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
          border-radius: 50%;
          margin-right: 12px;
          justify-content: center;
          align-items: center;

          .imgIcon {
            width: 100%;
            height: 100%;
          }
        }
      }

      .list:hover {
        color: var(--font-color);
        text-decoration: underline;
      }
    }

    .divCopyRight {
      width: 100%;
      background-color: var(--copyrigth-bg-color);
      display: flex;
      padding: 1rem;
      justify-content: space-between;

      .copyRight {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--white);
      }

      .copyRightUnderLine {
        text-decoration: underline;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--white);
      }

      .sobtidLink {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--white);

        span {
          margin-left: 24px;
        }

        span:hover {
          cursor: pointer;
          color: #008137;
          text-decoration: underline;
        }
      }
    }
  }
}
