.container {
  .close_logo {
    position: absolute;
    padding: 1rem;
    right: 0;
    top: 0;
  }

  .drawer_guest {
    .button_con {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      padding: 0.75rem;
    }
  }

  .user_container {
    width: 100%;
    padding-top: 2rem;

    .use_container {
      width: 100%;
      display: flex;

      .user_display {
        height: auto;
        padding-right: 0.75rem;

        .user_image {
          width: 100%;
          border-radius: 100%;
        }
      }

      .user_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .user_name {
          font-weight: 600;
        }

        .user_sub_info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .user_grade {
            color: #999899;
          }

          .user_package {
            .package {
              padding: 0.5rem;
              background-color: #ffeef5;
              color: #ee5294;
              border-radius: 0.25rem;
              font-size: 0.75rem;
            }
          }
        }
      }
    }
    .navigate_container {
      width: 100%;

      .navigate_item_active {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;

        color: #ee5294;
        padding: 1rem;
        margin: 1rem 0 1rem 0;
        border-radius: 2rem;
        background-color: #ffeef5;

        .navigate_text {
          font-size: 1.25rem;
          margin-left: 1rem;
        }
      }

      .navigate_item_non_active {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;

        padding: 1rem;
        margin: 1rem 0 1rem 0;
        border-radius: 2rem;

        .navigate_text {
          font-size: 1.25rem;
          color: #999899;
          margin-left: 1rem;
        }
      }
    }
    .action_container {
      width: 80%;
      position: fixed;
      bottom: 0;

      .user_detail {
        cursor: pointer;
        width: 100%;
        display: flex;
        padding: 1rem;

        color: #999899;
        font-size: 1.25rem;

        .detail_text {
          padding-left: 1rem;
        }
      }
    }
  }
}
