.share_container {
    display: flex;
    justify-content: space-evenly;

    .icon {
        font-size: 3rem;
    }
}

.clipboard {
    margin-top: 1.25rem;
}