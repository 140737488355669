.rhap_container {
  background: #0a0d14 !important;
  border-radius: 0.3rem !important;
  color: #ffffff !important;
}

.rhap_container .rhap_progress-bar {
  background-color: #555559 !important;
}

.rhap_time {
  color: #fff !important;
}

.rhap_main-controls-button svg {
  color: #ee5294 !important;
  width: 100% !important;
  height: 100% !important;
}
.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: #ee5294 !important;
}

.rhap_button-clear.rhap_volume-button {
  color: #ee5294 !important;
}

.rhap_volume-bar,
.rhap_volume-indicator {
  background-color: #ee5294 !important;
}

//? Allow
// .disablePause .rhap_play-pause-button[aria-label="Pause"] {
//   display: none !important; // Hide the pause button specifically
// }

// // Disable the seek bar (timeline) when seekTo is false
// .disableSeek .rhap_progress-container {
//   pointer-events: none !important; // Disable interaction with the seek bar
//   opacity: 0.5 !important; // Optionally make it look disabled
// }
// Hide the pause button when pause is false
.disablePause .rhap_play-pause-button[aria-label="Pause"] {
  //   display: none !important; // Hide the pause button specifically
  opacity: 0.5 !important;
  pointer-events: none !important;
}

// Disable the seek bar when seekTo is false
.disableSeek .rhap_progress-container {
  pointer-events: none !important; // Disable interaction with the seek bar
  opacity: 0.5 !important; // Optionally make it look disabled
}

// Hide the play button when audio has finished
.hidePlayButton .rhap_play-pause-button[aria-label="Play"] {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.hidePlayButton .rhap_progress-container {
  opacity: 0.5 !important;
  pointer-events: none !important;
}
