.pretest_table_container {
  width: 100%;
  height: auto;
  display: flex;
  padding: 16px;

  .table_left {
    width: 224px;
    height: 126px;

    .table_banner {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }

  .table_midle {
    padding-left: 12px;
    width: 60%;

    .up {
      margin-bottom: 20px;

      .table_name {
        font-family: ChulaCharasNew;
        font-size: 20px;
      }
      .div_publisher {
        display: flex;
        flex-direction: row;
        color: #999899;

        .pretest_vertical {
          margin: 0 8px 0 8px;
          border-left: 0.5px solid #999899;
          height: 16px;
          color: red;
        }
      }
    }

    .mid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 12px 0 12px 0;

      .item {
        display: flex;
        align-items: center;

        .stylelist {
          margin-right: 8px;
        }
      }

      .score {
        display: flex;
        align-items: center;

        .stylelist {
          margin-right: 8px;
        }
      }

      .time {
        display: flex;
        align-items: center;

        .stylelist {
          margin-right: 8px;
        }
      }
    }

    .date {
      margin-top: 20px;
    }

    .table_right {
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;

      .btnCard {
        width: 200px;
        height: 36px;
        margin-top: 1.5rem;

        @media screen {
        }
      }

      .action_button {
        display: flex;
        gap: 0.5rem;
        .button {
          height: 36px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.hovered {
  background-color: #ebebeb99;
  transition: 0.2s;
  cursor: pointer;
}
