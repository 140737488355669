.container {
  padding-bottom: 24px;
}
.divHead {
  display: flex;
  justify-content: space-between;
  height: 36px;
}
.right {
  display: flex;
  .btnDownload {
    margin-right: 20px;
  }
}

.right:hover {
    color: #EE5294 !important;
}

.rootSwitch {
  display: flex;
  margin-left: 16px;
  .divBtn {
    width: 48px;
    height: 48px;
    background-color: var(--white);
    padding: 12px;
  }
  .divBtn:hover {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    // background-color: #FFEEF5;
    cursor: pointer;
  }
  .divBtnSelected {
    width: 48px;
    height: 48px;
    // background-color: #FFEEF5;
    padding: 12px;
    border-radius: 50%;
    // background-color: var(--ice);
  }
  .divDevide {
    width: 24px;
    height: 24px;
    padding: 6px 12px 0px 12px;
    .devide {
      width: 1px;
      height: 36px;
      background-color: var(--very-light-pink);
    }
  }
}
.TitleRegRoot {
  display: flex;
  .regtangle {
    width: 6px;
    height: 36px;
    object-fit: contain;
    border-radius: 6px;
    background-color: var(--shamrock);
    margin-right: 10px;
    margin-top: 2px;
  }
  .txtTitle {
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
  }
}

.TitleRegRootSecond {
  display: flex;
  margin-top: 36px;
  
  .regtangleSecond {
    width: 6px;
    height: 30px;
    object-fit: contain;
    border-radius: 6px;
    background-color: var(--shamrock);
    margin-right: 10px;
    margin-top: 2px;
  }
  .txtTitleSecondary {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: var(--brownish-grey);
  }
}
.TitleRegRootModal {
  display: flex;
  // margin: 0px 0px 0px 16px;
  .regtangleModal {
    margin-top: 2px;
    width: 6px;
    height: 30px;
    object-fit: contain;
    border-radius: 6px;
    background-color: var(--shamrock);
    margin-right: 10px;
  }
  .txtTitleModal {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
  }
}
.btnCreate {
  width: 124px;
  height: 36px;
  padding: 0px;
  margin-right: 16px;
  margin-top: 5px;
}
.seeMore {
  margin-top: 7px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--brown-grey);
  // position: absolute;
  // right: 30px;
  // bottom: 19px;
  text-decoration: underline;
}
.seeMore:hover {
  cursor: pointer;
  color: #C53974;
}
