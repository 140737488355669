.bundle_card {
  width: 190px;
  cursor: pointer;
  margin: 0.5rem 0.15rem 0.5rem 0.15rem;

  .bundle_card_banner_hover {
    position: absolute;
    margin-top: 170px;
    background: linear-gradient(
      0deg,
      rgba(143, 143, 143, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 50px 1rem 25px 1rem;
    border-radius: 0.5rem;

    display: flex;
    justify-content: space-around;
    width: 190px;

    .icon {
      font-size: 20px;

      &:hover {
        color: #ee5294;
      }
    }
  }

  .bundle_card_banner {
    .bundle_banner {
      .bundle_card_banner_img {
        width: 190px;
        height: 263px;
        border-radius: 0.5rem;
      }
    }
  }

  .detail {
    .bundle_card_detail {
      font-size: 0.75rem;
      color: #999899;
      .bundle_card_detail_publisher {
        margin-top: 0.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
      }

      .bundle_card_detail_rating {
        display: flex;
        align-items: baseline;
        margin: 0.5rem 0 0.5rem 0;

        .bundle_card_detail_rating_amount {
        }
      }
    }

    .bundle_card_button {
      .bundle_card_button_buy {
        width: 190px;
      }
    }
  }
}
