.content {
  min-height: 70vh;
  background-attachment: fixed;
  width: 100%;
  .divLayout {
    background-color: transparent;
  }
  display: flex;
  flex-direction: column;
}
.header {
  position: "fixed";
  z-index: 1;
  width: "100%";
  background-color: "white";
  padding: 0px 23px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);
}
.Rectangle {
  width: 62px;
  height: 18px;
  opacity: 0;
  border-radius: 15px;
  background-color: #eaeaea;
}

.footer {
    flex-shrink: 0;
}