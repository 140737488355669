.title{
  display: flex; 
  margin-bottom: 5px;
  margin-top: 24px;
  .regtangle{
    width: 6px;
    height: 36px;
    object-fit: contain;
    border-radius: 6px;
    background-color: #EE5294;
    margin-right: 10px;
    margin-top: 2px;

    &.small {
      height: 24px;
    }
  }
  .text{
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);

    &.small {
      font-size: 18px;
      line-height: 1.5;
    }
  } 
}