.rootAlertBar {
    position: fixed;
    top: 65px;
    z-index: 10;
    left: 40%;
    background: white;
    box-shadow: 0px 0.6px 0.3px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.rootAlertBarTesting {
    position: fixed;
    top: 45px;
    z-index: 90;
    left: 40%;
    background: white;
    box-shadow: 0px 0.6px 0.3px 0px rgba(0, 0, 0, 0.3);
}