.header {
  position: fixed;
  z-index: 100;
  width: 100%;
  background: white;
  display: flex;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);

  .logo {
    min-width: 5rem;
    max-width: 7.5rem;
    width: 100%;
    height: auto;
  }
  .right_nav {
    display: flex;
    justify-content: flex-end;

    .button {
      // width: 100px;
    }
  }
  .divLogo {
    display: flex;
    margin-left: 24px;
    .listIcon {
      display: none;
    }
    .headLogo {
    }
  }
}

.header_guest {
  position: fixed;
  z-index: 100;
  width: 100%;
  background: white;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);

  .header_guest_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hamberger {
      padding: 1rem;
      margin: 0.5rem 0 0 0.5rem;
      position: absolute;
      left: 0;
      cursor: pointer;
    }

    .right_nav {
      margin-top: 0.75rem;
    }

    @media (max-width: 600px) {
      justify-content: center;
    }
  }
}

.header_user {
  position: fixed;
  z-index: 100;
  width: 100%;
  background: white;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);

  display: flex;
  padding: 0.25rem 5rem;
  justify-content: space-between;
  align-items: center;

  .user_hamberger {
    width: 32px;
    z-index: -1;
    padding: 1rem;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;

    .hamberger {
      width: 32px;
      height: auto;
    }
  }

  .nav {
    width: 100%;

    .top_nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left_header {
        display: flex;
        align-items: center;

        .divLogo {
          .logo {
            width: 120px;
            height: auto;
          }
        }

        .divMenuItem {
          display: flex;

          font-weight: bold;
          margin-left: 2rem;
          //   padding-top: 0.5rem;

          .divMenuItemLink {
            margin: 0 1rem 0 1rem;
            cursor: pointer;

            .btnMenuSelected {
              color: #ee5294;
            }
          }

          .divMenuItemLink:hover {
            color: #ff3d82;
            transition: 0.7s;
          }
        }
      }

      .right_nav {
        display: flex;
        justify-content: flex-end;
      }
    }

    .bottom_nav {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .left {
        max-width: 900px;
        width: 55%;
        display: flex;
        align-items: center;

        button {
          max-width: 3rem !important;
        }

        .menu {
          cursor: pointer;

          .ant-dropdown-menu-item {
            display: none;
          }
        }
      }

      .right {
        display: flex;
        font-size: 1rem;

        .button {
          cursor: pointer;

          &:hover {
            color: #ee5294;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
  }
}

.divSearch {
  position: relative;
  max-width: 450px;
  left: 20%;

  @media (max-width: 1200px) {
    left: 5vw;
  }
}

.subProfile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  .nameAndSchool {
    cursor: pointer;
    margin-right: 0.5rem;
    align-items: flex-end;

    .divDisplayName {
      height: 20px;
      font-size: 1rem;
    }
    .divGrade {
      font-size: 0.7rem;
    }
  }

  .display {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 50px;
    height: 100%;
    margin-right: 1rem;

    .avatar {
      width: 100%;
      height: auto;
    }
  }

  .divDropdown {
  }
}
