.package_container {
    text-align: center;
    .menu {
        width: 392px;
        height: 64px;
        border-radius: 32px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
        background-color: var(--white);
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding: 14px 16px;
        .menu_button {
            cursor: pointer;
            width: 172px;
            height: 36px;
            border-radius: 25px; 
            padding: 10px 12px;
        }
    }
    .carousel { 
        :global .ant-col {
            margin: auto;
        }
        margin-top: 24px; 
        .carousel_list_container {
            padding: 10px;
            .carousel_list {
                display: flex;
                justify-content: space-between;
                height: 120px;
                padding: 24px; 
                color: #383838;  
                border-radius: 12px;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);   
                background-color: var(--white);
                .div_left {
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    .text { 
                        margin-left: 16px; 
                    }
                    
                }
                .div_right {
                    text-align: right;
                    h4 {
                        text-decoration: underline;
                        color: #0baf50 !important;
                        cursor: pointer;
                    }
                    div {
                        margin-top: 25px;
                        color: #737373;
                        font-size: 12px;
                    }
                }
                img {
                    width: 72px;
                    height: 72px;
                }
                
            }
        } 
        .arrow_left, .arrow_right { 
            padding: 6px;
            border-radius: 50%;
            margin: auto;
            img {
                width: 24px;
                height: 24px;
            }
            width: 36px;
            height: 36px;
            box-shadow: 0 3px 6px 0 rgba(34, 181, 96, 0.24);
            border: solid 1px var(--dark-mint);
            background-color: var(--white);
            cursor: pointer;
        } 
        .dots_container { 
            .dots {
                align-self: center;
                list-style: none;
                margin: auto;
                padding: 0;
                display: flex;
                width: fit-content;
                li {
                    position: relative; 
                    margin-right: 8px;
                    display: grid;
                    &:before {  
                        content: '';
                        height: 8px;
                        width: 8px;
                        display: inline-block;
                        background: var(--shamrock);
                        border-radius: 6px;
                        cursor: pointer;
                        transition: all 300ms ease;  
                    }
                    &.active {
                        &:before {
                            width: 20px;
                            background: var(--shamrock);
                        }
                    }
                    .dot_white {  
                        top: 2px;
                        left: 2px;
                        position: absolute;
                        height: 4px;
                        width: 4px;
                        background: white;
                        border-radius: 50%;
                    }
                }
            }
        }  
    }  
    .detail {
        margin: 36px auto;
        width: 840px;
        .paragraph {
            display: flex;
            justify-content: space-between;
            .header {
                display: flex;
                .vertical_line {
                    margin-right: 8px;
                    width: 6px;
                    height: 24px;
                    object-fit: contain;
                    border-radius: 6px;
                    background-color: var(--shamrock);
                }
                .title_left {
                    font-weight: 600 !important; 
                }
                
            }
            .title_right {
                font-size: 16px;
                text-decoration: underline !important;
                color: #afafaf;
                cursor: pointer;
            }
            .text_paragraph_left, .text_paragraph_right {
                margin-top: 16px !important; 
            }
            .text_paragraph_left {
                font-weight: 600;
            }
            .text_paragraph_right {
                color: #737373;
            }
        }
    }
    .cancel_package {
        font-size: 16px;
        color: #afafaf;
        margin: auto;
        text-decoration: underline;
        cursor: pointer;
    }
}

.modal_content {
    margin-bottom: 24px;
    text-align: center;
    h4 {
        margin-top: 36px;
        color: #383838;
    }
    span {
        margin-top: 16px;
        color: #737373;
    }
}
.confirm_text { 
    font-size: 16px;
    color: #383838;
}

.confirm_input {
    margin-top: 12px;
    width: 100%;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.button_modal {
    margin-top: 36px;
    display: flex;
    button {
        width: 200px;
        height: 48px;
        margin: 0 6px;
    }
}

.modal_success {
    text-align: center;
    margin-top: 36px;
    h4 {
        margin-top: 36px;
        color: #383838;
    }
    span {
        margin-top: 16px;
        color: #737373;
    }
    button {
        margin-top: 36px;
        width: 200px;
        height: 48px; 
        span {
            margin: unset;
            color: white;
        }
    }
}
.history_container {
    margin: 36px 0px;
    width: 100%;
    // display: flex;
    --auto-grid-min-size: 18rem; 
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    grid-gap: 1rem;
    .history_card {
        position: relative;
        margin-right: 16px;
        padding: 12px 16px;
        height: 140px;
        min-width: 360px;
        width: 100%;
        border-radius: 12px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        .text_date, .text_name {
            display: flex;
            justify-content: space-between;
        }
        .text_date {
            .date {
                color: #737373;
            } 
        }
        .text_name {
            font-size: 16px;
            color: #383838;
            font-weight: 500;
            .name {
                text-align: left;
                width: 200px;
            }
        }
        .text_id {
            position: absolute;
            bottom: 12px;
            color: #737373;

        }
    } 
}

.load_more { 
    margin: auto;
    padding: 5px;
    width: 124px;
    height: 36px;
}
.empty_container {
    margin-top: 20px;
}
.btnGoBack {
    color: var(--shamrock);
    border: solid 1px var(--shamrock);
}