.modal_success {
    text-align: center;
    margin-top: 36px;
    padding: 16px 16%;
    img {
        width: 120px;
    }
    h4 {
        margin-top: 36px;
        color: #383838;
    }
    span {
        margin-top: 20px;
        color: #999899;
    }
    button {
        margin-top: 36px;
        width: 200px;
        height: 48px; 
        span {
            margin: unset;
            color: white;
        }
    }
}