.main {
  .button_con {
    margin: 0 .25rem 1rem .25rem;
  }
  .container {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
  }
}
