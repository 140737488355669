$widthItem: 300px;

.rootLgForm {
  max-width: 600px;
  margin-left: 170px;
}

.formItem {
  height: 60px;
}

.selectLanguage {
  max-width: $widthItem;
  margin: 12px 0px;
}

.confirm {
  width: $widthItem;
}