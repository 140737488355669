* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "THSarabun";
  src: local("THSarabun"), url("assets/font/THSarabun.ttf") format("truetype");
}

@mixin text {
  font-family: "Kanit";
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

html,
body,
#root {
  --white: #ffffff;
  font-family: "Kanit", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue",
    sans-serif, "THSarabun";
  letter-spacing: normal;

  h1 {
    @include text;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black);
  }

  h2 {
    @include text;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black);
  }

  h3 {
    @include text;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.46;
    color: var(--black);
  }

  h4 {
    @include text;
    font-weight: 550;
    line-height: 1.46;
    color: var(--black);
  }

  a {
    @include text;
    line-height: 1.5;
    color: #ee5294;
  }

  a:hover {
    @include text;
    line-height: 1.5;
    color: #ee5294;
    text-decoration: underline;
  }

  p {
    @include text;
    margin-bottom: 0px;
  }

  span {
    @include text;
  }
  ::placeholder {
    @include text;
  }
  div {
    @include text;
  }
}
::selection {
  color: #fff !important;
  background: #ee5294;
}
.ant-select-selector {
  min-height: 42px;
  align-items: center;
  border-radius: 10px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.inputForm {
  width: 350px;
  height: 48px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px var(--white);
  background-color: var(--white);
}

.inputForm:hover {
  width: 350px;
  height: 48px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c53974;
  background-color: var(--white);
}

.inputFormWarning {
  width: 350px;
  height: 48px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px var(--white);
  background-color: var(--white);
  color: var(--warning);
}

.textAreaForm {
  width: 468px;
  height: 96px !important;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px var(--white);
  background-color: var(--white);
}

.ant-btn {
  // border-radius: 30px;
  background-color: var(--white);
  padding: 0.875rem 1rem;
  height: 48px;
  font-size: 16px;
  &:disabled {
    color: #c2c2c2;
    background-color: #eaeaea;
    border: none;
    box-shadow: none;
  }

  @media (max-width: 600px) {
    font-size: 10px;
    padding: 0.5rem 0.4rem;
  }
}
// .ant-btn-sm
.ant-btn-sm {
  height: 36px !important;
  min-width: 124px !important;
  padding: 6px 16px !important;
  border-radius: 30px !important;
  font-size: 16px !important;
}
.ant-btn-text {
  font-size: 16px;
  font-weight: 550;
  color: var(--black);
  height: 36px;
  padding: 0px 15px;
  border: none;
}

.ant-btn-primary {
  color: var(--white);
  background: #ee5294;
  border-color: #ee5294;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  line-height: 0;
  &:focus:not(:disabled) {
    color: #fff;
    border-color: #ee5294;
    background-color: #ee5294;
    font-size: 16px;
  }
}

.ant-layout-header {
  height: auto;
  line-height: 56px;
  background: #ffffff;
}

.ant-layout-content {
  background-color: transparent;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  background: #eaeaea;
  border-color: transparent;
  border-radius: 30px;
  padding: 0px 15px;

  font-size: 16px;
  font-weight: 550;
  color: var(--black);
  height: 36px;
  padding: 0px 15px;
}

.ant-tabs-tab.ant-tabs-tab-active {
  //   width: auto;
  //   height: 36px;
  //   border-radius: 25px;
  //   background-color: var(--ice);
  //   text-align: center;
  //   font-size: 14px;
  //   font-weight: normal;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: 1.5;
  //   letter-spacing: normal;
  //   text-align: center;
  //   color: #ee5294;
  //   padding: 12px;
}

// .ant-tabs-tab:hover {
//     width: auto;
//     height: 36px;
//     border-radius: 25px;
//     background-color: #eaeaea;
//     text-align: center;
//     font-size: 14px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.5;
//     letter-spacing: normal;
//     text-align: center;
//     color: var(--border-line);
//     padding: 12px;
//     margin-right: 12px;
// }

// .ant-tabs-tab {
//   // width: 82px;
//   width: auto;
//   height: 36px;
//   border-radius: 25px;
//   background-color: #f8f7f7;
//   text-align: center;
//   font-size: 16px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: center;
//   padding: 12px;
//   margin-right: 12px;
// }

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
  display: none;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
  display: none;
}

.ant-menu-vertical {
  border-right: 0px solid #f0f0f0;
  margin-bottom: 12px;
  height: 64px;
  background-color: transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  height: auto !important;
}

.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  color: #000 !important;
  background-color: #f7f7f7;
  height: auto;
  border: 1px solid #ebebeb;
  line-height: 30px;
}

.ant-menu:not(.ant-menu-horizontal) {
  background-color: #fff !important;
}

.ant-menu-item-selected {
  color: #000 !important;
}

.ant-table-tbody > tr > td {
  font-size: 16px !important;
}

.ant-dropdown-menu-item {
  padding-left: 10px;
}

.ant-modal-content {
  border-radius: 12px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
}

.ant-modal-body {
  // padding: 1rem;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
  text-align: center;
}

.ant-select-dropdown {
  border-radius: 10px !important;
}

.ant-picker {
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
}

.ant-input-search {
  // border-radius: 30px !important;
  // border: 1px solid #ffffff;
  .ant-tabs-tab {
    width: 82px;
    height: 36px;
    // border-radius: 25px;
    background-color: #f8f7f7;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    padding: 12px;
    margin-right: 12px;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    bottom: 0;
    display: none;
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    bottom: 0;
    display: none;
  }

  .ant-menu-vertical {
    border-right: 0px solid #f0f0f0;
    margin-bottom: 12px;
    height: 64px;
    background-color: transparent;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }

  .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #ffffff;
    border-bottom: 1px solid #f0f0f0;
    font-size: 16px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
  }

  .ant-table-tbody > tr > td {
    font-size: 16px !important;
  }

  .ant-dropdown-menu-item {
    padding-left: 10px;
  }

  .ant-dropdown-menu {
    border-radius: 6px;
  }

  .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
  }

  .ant-modal-body {
    // padding: 36px 24px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 2px;
    text-align: center;
  }

  .ant-input {
    // border-radius: 10px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .ant-input-affix-wrapper {
    border-radius: 10px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    .ant-input {
      border-radius: unset;
      box-shadow: unset;
      background: white;
    }

    .ant-input-suffix {
      display: unset;
    }
  }
}

.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 0px solid #ffffff;
  border-radius: 2px 2px 0 0;
}

.ant-back-top-content {
  color: white;
}

.ant-skeleton-element .ant-skeleton-button {
  width: 80px !important;
  height: 80px;
}

.ant-skeleton-button-sm {
  height: 36px !important;
}

tspan {
  margin-top: 15px;
  font-size: 4px !important;
}

#react-mathjax-preview-result {
  img {
    width: 360px !important;
    height: unset !important;
    position: center;
  }

  p {
    font-size: 18px;
    font-family: Sarabun;
  }
}

.ant-form-item-with-help {
  margin-bottom: 0;
  height: 80px !important;
}

.ant-dropdown-menu {
  border-radius: 4px;
  padding: 0px;
}

.navRight {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

#react-mathjax-preview-result {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.underline-tab-nav-list {
  display: flex;
}

// refhttps://stackoverflow.com/questions/51521478/how-to-change-the-length-position-of-text-decorationunderline?noredirect=1&lq=1
.underline-tab-tab.underline-tab-tab-active {
  width: auto;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--border-line);
  padding: 6px;
  cursor: pointer;

  background-image: linear-gradient(#fed148, #fed148);
  background-position: bottom center;
  background-size: 50% 2px;
  background-repeat: no-repeat;
  padding-bottom: 4px;
}

.underline-tab-tab {
  width: auto;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--border-line);
  padding: 6px;
  cursor: pointer;
}

.underline-tab-tab:hover {
  border-radius: 10px;
  background-color: rgba(38, 165, 16, 0.04);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media print {
  #react-mathjax-preview-result {
    display: none;

    /* hide whole page */
    p {
      display: none;
    }
  }
}

.ant-dropdown-menu-vertical {
  border-radius: 6px;
  padding: 0px !important;
}

.ant-dropdown-menu-item {
  padding: 10px !important;
  min-height: 46px;
}

.displayNone {
  display: none;
}

.show-border {
  border: 1px solid red;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

[class^="react-stars-wrapper"] {
  justify-content: center !important;
}

.react-tabs__tab-list {
  box-shadow: inset 0px -3px 9px -3px #00000014;
  border-bottom: none !important;
}

.react-tabs__tab {
  border: none !important;
  box-shadow: none !important;
}
.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
  border: none !important;
  box-shadow: none !important;
}
.react-tabs__tab--selected {
  outline: none;
  content: "";
  // position: absolute;
  height: 26px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px #00000029 !important;
}

// .rhap_container {
//   background: #0a0d14;
//   border-radius: 0.3rem;
//   color: #ffffff;
// }

// .rhap_container .rhap_progress-bar {
//   background-color: #555559;
// }

// .rhap_time {
//   color: #fff;
// }

// .rhap_main-controls-button svg {
//   color: #ee5294;
//   width: 100%;
//   height: 100%;
// }
// .rhap_progress-filled,
// .rhap_progress-indicator {
//   background-color: #ee5294 !important;
// }

// .rhap_button-clear.rhap_volume-button {
//   color: #ee5294 !important;
// }

// .rhap_volume-bar,
// .rhap_volume-indicator {
//   background-color: #ee5294;
// }

.ant-collapse .ant-collapse-content .ant-collapse-content-box {
  padding: 0 !important;
}