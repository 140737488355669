.main {
  width: 100%;
  cursor: pointer;
  transition: 1s;
  padding: 0.25rem;

  .container {
    width: 100%;
    margin: 0.5rem 0 0.5rem 0;
    display: flex;

    .left {
      margin-right: 1rem;
      .bundle_card_banner {
        .bundle_banner {
          width: 169px;
          border-radius: .5rem;

          .bundle_card_banner_img {
            width: 100%;
          }
        }
      }
    }

    .right {
      width: 100%;

      .name {
        font-size: 1.15rem;
        font-weight: bold;
      }

      .publisher {
        margin-top: 1.25rem;
        font-size: 0.75rem;
        color: #999899;
      }

      .review {
        display: flex;
        align-items: baseline;
        margin-bottom: 0.5rem;

        .amount {
          margin-right: 1rem;
          color: #999899;
        }
      }

      .detail {
        display: flex;

        .data_amount {
          display: flex;
          align-items: center;

          .icon {
            margin-right: 0.5rem;
          }

          .data {
          }
        }
      }

      .button_container {
        width: 50%;
        display: grid;
        grid-template-columns: 3fr 1fr 1fr;
        gap: 1rem;
        margin-top: 1rem;

        @media (max-width: 800px) {
          width: 100%;
        }
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
  &:hover {
    background-color: #dcdcdc17;
    transition: 1s;
  }
}
