.divMenu {
  border-radius: 4px;

  .divItem {
    width: 210px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;

    .regTangle {
      width: 4px;
      height: 28px;
      margin-right: 5px;
      border-radius: 2px;
      background-color: white;
    }

    .divLabel {
      width: 210px;
      position: relative;
      padding-top: 5px;

      .icon {
        width: 24px;
        height: 24px;
        margin-right: 13px;
      }

      .txtMenu {
        font-size: 14px;
        font-weight: 600px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #737373;
      }

      .divDivider {
        width: 153px;
        height: 1px;
        background-color: #eaeaea;
        margin-top: 5px;
        position: absolute;
        bottom: -5px;
      }
    }
  }

  .divItem:hover {
    width: 210px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;

    .regTangle {
      width: 4px;
      height: 28px;
      margin-right: 5px;
      border-radius: 2px;
      background-color: var(--shamrock);
    }

    .divLabel {
      width: 210px;
      position: relative;
      padding-top: 5px;

      .icon {
        width: 24px;
        height: 24px;
        margin-right: 13px;
      }

      .txtMenu {
        font-size: 14px;
        font-weight: 600px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--shamrock);
      }

      .divDivider {
        width: 153px;
        height: 1px;
        background-color: #eaeaea;
        margin-top: 5px;
        position: absolute;
        bottom: -5px;
      }
    }
  }
}

.btnNav {
  padding: 6px 16px !important;
  height: 40px !important;
}