.divMenu{
  border-radius: 4px;
  .divItem{
    width: 194px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;

    .regTangle{
      width: 4px;
      height: 28px;
      border-radius: 2px;
      background-color: white;
    }
    .divLabel{
      width: 170px;
      position: relative;
      padding-top: 5px;
      .icon{
        width: 24px;
        height: 24px;
        margin-right: 13px;
      }
      .txtMenu{
        font-size: 14px;
        font-weight: 600px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #737373;
      }
      .divDivider{
        width: 100%;
        height: 1px;
        background-color: #eaeaea;
        margin-top: 5px;
        position: absolute;
        bottom: -5px;
      }
    }
  }
  .divItem:hover{
    width: 194px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    
    .regTangle{
      width: 4px;
      height: 28px;
      border-radius: 2px;
      background-color: #EE5294;
    }
    .divLabel{
      width: 170px;
      position: relative;
      padding-top: 5px;
      .icon{
        width: 24px;
        height: 24px;
        margin-right: 13px;
      }
      .txtMenu{
        font-size: 14px;
        font-weight: 600px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #EE5294;
      }
      .divDivider{
        width: 100%;
        height: 1px;
        background-color: #eaeaea;
        margin-top: 5px;
        position: absolute;
        bottom: -5px;
      }
    }
  }
}