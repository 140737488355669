.rootForgotPassword {
  display: flex;
  justify-content: center;

 .inputBox {
    input {
      border: 2px solid #D6D6D6 !important;
    } 
  }

  .title {
    font-size: 24px;
    margin-top: 24px;
  }

  .divBack {
    margin-top: 36px;
    display: flex;
    justify-content: center;

    .linkBack {
      text-align: center;
      color: var(--brown-grey);
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

}

.btnLogin {
  width: 350px;
  margin-top: 24px;
}

.divStep {
  margin-top: 24px;
  // padding-left: 24px;
}