.content{
    height: 100vh;
    background-attachment: fixed;
    width: 100%;
    background-size: 140%;
    .divLayout{
        background-color: transparent;
        min-height: 86vh;
        // text-align: center;
        .header{
            position: 'fixed' !important;
            z-index : 1 !important;
            width: '100%'!important; 
            background-color : 'white';
            padding: 0px 23px;
            display: flex;
            justify-content: space-between;
            box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);
            .headerProfile {
                display: flex;
                .subProfile {
                    display: flex;
                    min-width: 145px;
                    cursor: pointer;
                    .roleAndSchool {
                        position: relative;
                        right: 13px;
                        .role {
                            position: absolute;
                            bottom: 7px;
                            right: 0px;
                        }
                        .school {
                            width: 150px;
                            position: absolute;
                            top: 12px;
                            right: 0px;
                            height: 35px; 
                            .fontStyle {
                                font-size: 12px;
                                color: #737373;
                            }
                        }
                    }
                    .tagStyle {
                        padding: 0 9px 0 16px;
                        cursor: pointer;
                    }
                }
            }
        }
        .divChildren{
            padding: 24px;
            background-color: transparent;
            width: 1200px;
            overflow: hidden;
        }
    }
    
    .Rectangle {
        width: 62px;
        height: 18px;
        opacity: 0;
        border-radius: 15px;
        background-color: #eaeaea;
      }
        .inputSearch{
            width: 350px;
            height: 36px;
            border-radius: 20px;
            background-color: transparent;
            margin-left: 63px;
            margin-top: 0.65em;
            background-color: #f8f7f7;
            input {
                width: 90%;
                border-radius: unset !important;
                box-shadow: unset !important;
                background-color: #f8f7f7;
            }
      }
      .divMenu{
          width: 210px;
          padding-left: 30px;
          .divMenuItem{
              margin-bottom: 12px;
            .btnMenu{
                width: 160px;
                height: 48px !important;
                font-size: 16px;
                font-weight: 550;
                color: var(--black);
                padding: 0px 0px !important;
                border: none;
                font-weight: 600 !important;
                font-stretch: normal;
                color: var(--brown-grey) !important;
                text-align: left;
                padding-left: 24px !important;
            }
            .btnMenu:hover{
                color: var(--shamrock )!important;
            }
            .btnMenuSelected{
                width: 160px;
                height: 48px !important;
                font-size: 16px;
                font-weight: 550;
                color: var(--black);
                padding: 0px 0px !important;
                border: none;
                font-weight: 600 !important;
                font-stretch: normal;
                color: var(--shamrock )!important;
                background-color: var(--ice) !important;
                text-align: left;
                padding-left: 24px !important;
            }
          }
      }
      .navbarPDPA {
        position: fixed;
        display: flex;
        z-index: 100;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        height: 64px;
        bottom: 0px;
        align-items: center;
        padding: 8px 128px 8px 128px;
        justify-content: space-between;
        span {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: var(--white);
            z-index: 90;
        }
        .linkAccept {
            color: #ffff;
            font-weight: 800;
            text-decoration: underline;
        }
      }
}

.divRoot{
    position: fixed;
    width: 100%;
    bottom: 0px;
    .divImg{
        background: bottom center no-repeat url('../../assets/images/bgjunior.svg');
        height: 128px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    .divPadding{
        position: absolute;
        top: 127px;
        width: 100%;
        .divSiteMap{
            width: 100%;
            height: 177px;
            background-color: #77e489;
            padding: 24px 128px 16px 128px;
            text-align: left;
            span{
                display: block;
            }
            .title{
                font-family: Kanit;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #00913d;
                margin-bottom: 18px;
            }
            .list{
                cursor: pointer;
                font-family: Kanit;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                // color: #c9ffd2;
                color: #00913d;
                margin-bottom: 8px;
                display: flex;
                opacity: 0.75;
                .Oval{
                    width: 36px;
                    height: 36px;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
                    background-color: var(--white);
                    border-radius: 50%;
                    padding: 6px;
                    margin-right: 12px;
                    .imgIcon{
                       width: 100%;
                       height: 100%;
                    }
                }
              
            }
            .list:hover{
                color: #01be26;
                text-decoration: underline;
            }
        }
        .divCopyRight{
            width: 100%;
            height: 36px;
            background-color: #4ec580;
            display: flex;
            padding: 8px 128px 8px 128px;
            justify-content: space-between;
            .copyRight{
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: var(--white);
            }
            .sobtidLink{
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: var(--white);
                span{
                    margin-left: 24px;
                }
                span:hover{
                    cursor: pointer;
                    color: #008137;
                    text-decoration: underline;
                }
            }
        }
    }
    
}

