.modal_wrong {
    text-align: center;
    margin-top: 36px;
    img {
        width: 120px;
    }
    h4 {
        margin-top: 36px;
        color: #383838;
    }
    span {
        margin-top: 16px;
        color: #737373;
    }
    button {
        margin-top: 36px;
        width: 200px;
        height: 48px; 
        span {
            margin: unset;
            color: white;
        }
    }
}